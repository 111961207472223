import './App.css';
import * as React from "react";
import axios from "axios";

const API_ENDPOINT = 'http://139.162.36.144:8586/search';

const ActionType = {
    RESULT_FETCH_INIT    : 'RESULT_FETCH_INIT',
    RESULT_FETCH_OK      : 'RESULT_FETCH_OK',
    RESULT_FETCH_ACCEPTED: 'RESULT_FETCH_ACCEPTED',
    RESULT_FETCH_FAILURE : 'RESULT_FETCH_FAILURE',
    RESULT_FETCH_RESET   : 'RESULT_FETCH_RESET'
};

const App = () => {
    const [searchDetails, setSearchDetails] = React.useState({
        tableNumber: 0,
        period: 0
    });

    const handleInputChange = (evt) => {
        const target = evt.target;
        const updatedSearchDetails = {
            ...searchDetails,
            [target.name]: target.value
        };
        setSearchDetails(updatedSearchDetails);
    };

    React.useEffect(() => {
        dispatchResult({ type: ActionType.RESULT_FETCH_RESET });
    }, [searchDetails]);

    const resultReducer = (state, action) => {
        switch (action.type) {
            case ActionType.RESULT_FETCH_RESET:
                return {
                    isLoading: false,
                    cardHistory: null,
                    message: null
                };
            case ActionType.RESULT_FETCH_INIT:
                return {
                    //...state,
                    isLoading: true,
                    cardHistory: null,
                    message: null
                };
            case ActionType.RESULT_FETCH_OK:
                return {
                    isLoading: false,
                    cardHistory: action.payload,
                    message: null
                };
            case ActionType.RESULT_FETCH_ACCEPTED:
                return {
                    isLoading: false,
                    cardHistory: null,
                    message: action.payload
                };
            case ActionType.RESULT_FETCH_FAILURE:
                return {
                    isLoading: false,
                    cardHistory: null,
                    message: action.payload
                };
            default:
                throw new Error();
        }
    };

    const [result, dispatchResult] = React.useReducer(
        resultReducer,
        { cardHistory: null, message: null, isLoading: false }
    );

    const handleSubmit = () => {
        sendRequest();
    };

    const sendRequest = async () => {
        dispatchResult({ type: ActionType.RESULT_FETCH_INIT });
        try {
            const response = await axios.post(API_ENDPOINT, searchDetails);

            if (response.status === 200) {
                //http status 200 (ok)
                dispatchResult({
                    type: ActionType.RESULT_FETCH_OK,
                    payload: response.data
                });
            }
            else if (response.status === 202) {
                //http status 202 (accepted)
                dispatchResult( {
                    type: ActionType.RESULT_FETCH_ACCEPTED,
                    payload: response.data
                });
            }
        }
        catch {
            dispatchResult({
                type: ActionType.RESULT_FETCH_FAILURE,
                payload: 'Pencarian error'
            });
        }
    }

    return (
        <div className="App">
            <div className="search-box">
                <InputWithLabel title="Meja : "
                                type="text"
                                id="tableNumber"
                                name="tableNumber"
                                value={searchDetails.tableNumber}
                                onInputChange={handleInputChange} />
                <InputWithLabel title="Periode : "
                                type="text"
                                id="period"
                                name="period"
                                value={searchDetails.period}
                                onInputChange={handleInputChange} />
                <button className="button"
                        type="button"
                        disabled={result.isLoading}
                        onClick={handleSubmit}>
                    Cari
                </button>
            </div>
            <Console {...result} />
        </div>
    );
};

const InputWithLabel = ({ title, type, id, name, value, onInputChange }) => (
    <div className="input-line-box">
        <label htmlFor={id} className="title">{title}</label>
        <input type={type}
               id={id}
               name={name}
               value={value}
               onChange={(evt) => onInputChange(evt)} />
    </div>
);

const Console = ({ cardHistory, message, isLoading }) => (
    <div className="console">
        {isLoading && <p>Sedang mencari data kartu...</p>}

        {message != null && <p>{message}</p>}

        {cardHistory != null && <CardHistoryPane {...cardHistory} />}
    </div>
);

const CardHistoryPane = ({
  tableNumber,
  nickname,
  period,
  dateCreated,
  card1,
  card2,
  card3,
  card4,
  card5,
  card6,
  card7,
  tipeJadi,
  tipeJadiExtra,
  cardFilterType
}) => {
    const cards = `${card1} ${card2} ${card3} ${card4} ${card5} ${card6} ${card7}`;
    const filter = `${cardFilterType.id} (${cardFilterType.title})`;

    return (
      <table>
        <Row title="Meja" value={tableNumber} />
        <Row title="Nickname" value={nickname} />
        <Row title="Periode" value={period} />
        <Row title="Kartu" value={cards} />
        <Row title="Jadi" value={tipeJadi.title} />
        <Row title="Jadi Extra" value={tipeJadiExtra.title} />
        <Row title="Filter" value={filter} />
        <Row title="Waktu" value={dateCreated} />
      </table>
    );
};

const Row = ({ title, value }) => (
    <tr>
        <td className="row-title">{title}</td>
        <td>{value}</td>
    </tr>
);

export default App;
